import  React, {useEffect, useState} from 'react'
import { TabItem, Tabs } from "flowbite-react";
import { HiDocument, HiFolder, HiFolderAdd  } from "react-icons/hi";
import axios from 'axios';

const backendIP = process.env.BACKEND_IP;


const AssetsHub = () => {

    const [isClicked, setIsClicked] = useState(false);
    const [assetData, setAssetData] = useState(null);

    const fetchADataFromBackend = () => {
        // Get token from local storage
        const token = localStorage.getItem('token');
    
        // Check if token exists
        if (token) {
          // Set Authorization header with the token
          const config = {
            headers: {
              Authorization: `Bearer ${token}`
            }
          };
    
          // Make a GET request to fetch data from backend
          axios.get(`${backendIP}/api/Doc_upload/Retrive_Doc_Data`, config)
            .then((response) => {
              setAssetData(response.data);
              console.log(response.data);
            })
            .catch((error) => {
              console.error('Error fetching data:', error);
            });
        } else {
          console.error('Token not found in local storage');
        }
    };

    useEffect (() => {
        fetchADataFromBackend();
    }, []);



    return (
            <div class=" w-full h-screen pattern-wavy pattern-gray-200 pattern-bg-white pattern-size-6 pattern-opacity-100">
                <div className="container flex justify-center items-center pt-[100px] pl-[300px]">


                        <Tabs aria-label="Tabs with icons" style="default" className='w-[100px] bg-white' onClick={() => { setIsClicked(!isClicked);}}>
                            <Tabs.Item title="Assets" icon={HiDocument} >
                                <div className="rounded-3xl shadow-lg bg-white h-[700px] w-[600px] mt-[-20px] ml-[-15px]">
                                    
                                    <div className="bg-white rounded-3xl flex justify-center pt-5">
                                            <table class="w-[80%] text-md font-thin rtl:text-right text-gray-500 dark:text-gray-400">
                                                <thead class="text-xs text-[#E17272] uppercase bg-white dark:bg-gray-700 dark:text-gray-400">
                                                    <tr>
                                                        <th scope="col" class="px-3 pb-3 font-normal text-left rounded-s-lg">
                                                            Asset Type:
                                                        </th>
                                                        <th scope="col" class="px-6 pb-3 font-normal text-center mr-5 rounded-e-lg">
                                                            Expiry Date:
                                                        </th>
                                                    </tr>
                                                </thead>
                                            </table>
                                            
                                    </div>
                                    <div className="w-[85%] h-[80%] ml-5 overflow-auto">

                                        {assetData && Array.isArray(assetData) && (
                                        assetData.map((Asset) => (

                                            <a class="rounded-sm ml-11 mb-5 grid grid-cols-12 bg-[#ffdada] shadow-md p-3 items-center hover:shadow-lg hover:bg-red-100 focus:outline-none focus:border-2 focus:border-red-600" href="#">


                                                    <div class="col-span-2 md:col-span-1">
                                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="#E17272">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2" />
                                                        </svg>
                                                    </div>
                                                            
                                                        
                                                    <div class="col-span-7">
                                                        <p class="text-[#E17272] font-semibold"> {`${Asset.doc_type}`} </p>
                                                    </div>

                                                    <div class="col-span-3">
                                                        <p class="text-[#E17272] font-semibold text-xs">  {Asset.doc_expiry && Asset.doc_expiry.trim() !== '' ? Asset.doc_expiry : 'null'} </p>
                                                    </div>
                                                            
                                                            
                                            </a>                                       

                                        
                                            ))
                                        )}
                                        {/* Display a message if addressData is null or not an array */}
                                        {!assetData && <p className="text-red-700 ml-12">No asset data available</p>}
                                        {assetData && !Array.isArray(assetData) && <p className="text-red-700 ml-7">Invalid contact data format</p>}
                                    </div>
                                    
                                </div>
                            </Tabs.Item >
                        
                        
                        </Tabs>  

                        <div className="rounded-3xl h-[700px] w-[600px] ml-[600px] mt-[50px]">

                

                            <div className="rounded-3xl shadow-lg bg-[#FFE9E9] h-[310px] w-[350px]">

                                

                                <div className="bg-white h-[100px] rounded-t-3xl flex pt-5">
                                        
                                    <h1 className="pt-4 pl-6 text-[#DE4646] text-3xl font-semibold">Request :</h1>
                                </div>

                                <form>
                                    <div className="grid grid-cols-10">
                                        <div className="w-[250px] col-span-7">
                                            <label htmlFor="name" className="mx-3 my-2 text-[#E17272]">
                                            Search User :
                                            </label>
                                            <input
                                            type="text"
                                            id="name"
                                            name="name"
                                            placeholder="Email Address"
                                            className="w-[85%] h-[40px] px-3 py-3 my-2 mx-3 text-[#b85959] placeholder-[#b85959] bg-[#F6C1C1]  focus:outline-none focus:border-[#DE4646]"
                                            />

                                            

                                        </div>
                                        
                                        <div className="col-span-1 float-left mt-9">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#E17272" className="w-8 h-8 hover:fill-red-800">
                                            <path d="M8.25 10.875a2.625 2.625 0 1 1 5.25 0 2.625 2.625 0 0 1-5.25 0Z" />
                                            <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-1.125 4.5a4.125 4.125 0 1 0 2.338 7.524l2.007 2.006a.75.75 0 1 0 1.06-1.06l-2.006-2.007a4.125 4.125 0 0 0-3.399-6.463Z" clipRule="evenodd" />
                                            </svg>
                                        </div>
                                    </div>
                                </form>

                                <div className="w-full text-[#D14343] text-xs">
                                    <h1 className="mx-4 mt-2 text-sm">Need help?</h1>
                                
                                    <p className="mx-4 text-[#F48787]">You can request packages here! all you need is the Users Email, if the user exists you can fill out a form request for assets you wish to see.</p>
                                </div>
                                
                            </div>
                            
                        </div>                  
                    </div>
                    
                

                


            </div>
    )
};

export default AssetsHub