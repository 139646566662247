//import logo from './logo.svg';
import './App.css';
import React from 'react';
import Navbar from './components/Navbar.jsx';
import Banner from './components/Banner.jsx';
import Services from './components/Services.jsx';
import Cards from './components/Cards.jsx';
import Footer from './components/Footer.jsx';
import Signup from './components/Signup.jsx';
import Login from './components/Login.jsx';
import Tools from './components/Tools.jsx';
import AssetsHub from './components/AssetsHub.jsx';
import AssetsUpload from './components/UploadHub.jsx';
import Profile from './components/Profile.jsx';
import WelcomeUser from './components/WelcomeUser.jsx';
import LinkedAccount from './components/LinkedAccount.jsx';
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import PrivateRoutes from './components/PrivateRoute.js';


const backendIP = process.env.BACKEND_IP;
console.log("Backend IP:", backendIP);
function Homepage() {
  return (
    <>
      <Navbar/> 
      <Banner/>
      <Services/>
      <Cards/>
      <Footer/>
    </>
  )
}

function HomepageAuth() {
  return (
    <>
      <Navbar/>
      <Banner/>
      <Services/>
      <Cards/>
      <Footer/>
    </>
  )
}

function HomeAuth(){
  return (
    <>
      <Navbar/> 
      <Banner/>
      <WelcomeUser/>
      <Tools/>
      <Footer/>
  </>
  )
}

function Signuppage(){
  return (
    <>
      <Navbar/> 
      <Signup/>
      <Footer/>
  </>
  )
}

function Loginpage(){
  return (
    <>
      <Navbar/> 
      <Login/>
      <Footer/>
  </>
  )
}

function AssetHub(){
  return (
    <>
      <Navbar/> 
      <AssetsHub/>
      <Footer/>
  </>
  )
}

function UploadsHub(){
  return (
    <>
      <Navbar/> 
      <AssetsUpload/>
      <Footer/>
  </>
  )
}

function ProfileHub(){
  return (
    <>
      <Navbar/> 
      <Profile/>
      <Footer/>
  </>
  )

}


function Linked(){
  return (
    <>
      <Navbar/> 
      <LinkedAccount/>
      <Footer/> 
    </>
  )
}

function App() {

  return (
    <>
    <BrowserRouter>
      <Routes>

        {/* public routes */}
        <Route path='/home' element={<Homepage/> } />
        <Route path='/signup' element={<Signuppage/> } />
        <Route path='/login' element={<Loginpage/> } />

        {/* private routes */}
        <Route element={<PrivateRoutes />}>
          <Route path='/dashboard' element={<HomeAuth/> } />
          <Route path='/home.auth' element={<HomepageAuth/> } />
          <Route path='/assets' element={<AssetHub/> } />
          <Route path='/upload' element={<UploadsHub/> } />
          <Route path='/profile' element={<ProfileHub/> } />
          <Route path='/linked' element={<Linked/> } />
        </Route>
        {/* catch all route */}
        
        
      </Routes>
    </BrowserRouter>
    </>
  );
}



export default App;

