import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { TypeAnimation } from 'react-type-animation'
const backendIP = process.env.BACKEND_IP;
console.log("Backend IP:", backendIP);

const WelcomeUser = ( ) => {

  const [userData, setUserdata] = useState(null);

  useEffect(() => {
    // Fetch data from backend when component mounts
    fetchDataFromBackend();
  }, []);

  const fetchDataFromBackend = () => {
    // Get token from local storage
    const token = localStorage.getItem('token');

    // Check if token exists
    if (token) {
      // Set Authorization header with the token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      // Make a GET request to fetch data from backend
      axios.get(`${backendIP}/api/Auth/userinfo`, config)
        .then((response) => {
          setUserdata(response.data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    } else {
      console.error('Token not found in local storage');
    }
  };

  








    return (
    <div className="flex w-full h-[20] flex-col justify-center items-center bg-white shadow-lg">
      {/* The main parent div */}
      {userData ? (
        <p className='text-4xl font-semibold text-red-500 p-4'>Welcome {userData.firstName} {userData.lastName}</p>
      ) : (
        <p className='bg-white'><TypeAnimation
        sequence={[
          // Same substring at the start will only be typed out once, initially
          'Loading',
          100, // wait 1s before replacing "Mice" with "Hamsters"
          'Loading..',
          100,
          'Loading...',
          100,
          '',
    
        ]}
        wrapper="span"
        speed={50}
        className="text-red-500"
        repeat={Infinity}
      /></p>
      )}
    </div>
  );
}


export default WelcomeUser;