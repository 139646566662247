import React, {useState, useEffect, useRef} from 'react'
import Cyber from '../assets/Cyber.png';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import {  toast } from 'react-toastify';
import * as faceapi from 'face-api.js';

const backendIP = 'http://164.90.253.147/';
console.info("Backend IP:", backendIP);
const Signup = ( ) => {

  const navigate = useNavigate();
  const [modelsLoaded, setModelsLoaded] = useState(false);
  const [captureVideo, setCaptureVideo] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const mediaRecorderRef = useRef(null);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [videoFile, setvideofile] = useState([]);

  const [loginname, setName] = useState('');
  const [type, setType] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [password, setPassword] = useState('');
  const [confirmpassword, setConfirmPassword] = useState('');
  const [securityemail, setSecurityEmail] = useState('');
  const [validForm, setValidForm] = useState(true);
  const videoRef = useRef();
  const canvasRef = useRef();
  const videoHeight = 480;
  const videoWidth = 640;

  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64Result = reader.result.split(',')[1]; // Strip the data URL prefix.
        resolve(base64Result);
      };
      reader.onerror = reject;
    });
  };

  const handleFirstnameChange = (value) => {
    setFirstname(value);
  }

  const handleLastnameChange = (value) => {
    setLastname(value);
  }

  const handleNameChange = (value) => {
    setName(value);
  }

  const handlePasswordChange = (value) => {
    setPassword(value);
  }

  const handleTypeChange = (value) => {
    setType(value);
  }
  const handleSecurityEmailChange = (value) => {
    setSecurityEmail(value);
  }

  const handleConfirmPasswordChange = (value) => {  
    setConfirmPassword(value);
  }

  const validateEmail = (email) => {
    // Simple email validation using regular expression
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSave  = async (event) => {
    event.preventDefault();

    // Check if all fields are filled
    if (
      firstname.trim() === '' ||
      lastname.trim() === '' ||
      loginname.trim() === '' ||
      securityemail.trim() === '' ||
      password.trim() === '' ||
      confirmpassword.trim() === '' ||
      type.trim() === ''
    ) {
      setValidForm(false);
      toast.warning('Please fill in all fields', { position: 'top-center' });
      return;
    }

    // Check if passwords match
    if (password !== confirmpassword) {
      setValidForm(false);
      toast.warning("Please enter matching passwords.", { position: 'top-center' });
      return;
    }

    // Check if Emails match
    if (loginname === securityemail) {
      setValidForm(false);
      toast.warning("Security Email cannot be the same as Primary Email.", { position: 'top-center' });
      return;
    }

    if (!validateEmail(loginname)) {
      setValidForm(false);
      toast.warning("Please enter a valid Primary email address.", { position: 'top-center' });
      return;
    }

    if (!validateEmail(securityemail)) {
      setValidForm(false);
      toast.warning("Please enter a valid Security email address.", { position: 'top-center' });
      return;
    }

    const videoBlob = new Blob(recordedChunks, { type: 'video/webm' });
    const base64Video = await blobToBase64(videoBlob);

    const data = {
      
      user_type: type,
      name_first: firstname,
      name_last: lastname,
      login_name: loginname,
      password: password,
      security_email: securityemail,
      id_verification: base64Video,

    };

    console.log(data);
    
    const url = `${backendIP}/api/Auth/Register`; // Make sure to include the protocol (http:// or https://)
    
    axios.post(url, data)
      .then((response) => {
        
          navigate('/login');
          toast.success('Registration successfully!', { position: 'top-center' }); 
          
        
      })
      .catch((error) => {
        console.log('Error:', error); // Log the entire error object
        if (error.response) { // Check if error response exists
          alert('User creation failed: ' + JSON.stringify(error.response.data));
        } else {
          alert('Network error occurred: ' + error.message); // Handle network errors separately
        }
        console.error('Error creating user:', error);
      });
  };

  
  useEffect(() => {

    if (isVerified) {
      alert("Your identity has been verified successfully!");
    }

    const loadModels = async () => {
      const MODEL_URL = process.env.PUBLIC_URL + '/models';
      await Promise.all([
        faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
        faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
        faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
        faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
      ]).then(() => setModelsLoaded(true));
    };
    loadModels();
  }, [isVerified]);


  const startVideo = () => {
    setCaptureVideo(true);
    navigator.mediaDevices.getUserMedia({ video: { width: 300 }  })
      .then(stream => {
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          videoRef.current.play();
          startRecording(stream);
        }
      })
      .catch(err => {
        console.error("error:", err);
      });
  };

  const startRecording = (stream) => {
    const options = { mimeType: 'video/webm; codecs=vp9' };
    const mediaRecorder = new MediaRecorder(stream, options);
    mediaRecorder.ondataavailable = handleDataAvailable;
    mediaRecorder.start();
    setIsRecording(true);
    mediaRecorderRef.current = mediaRecorder;
  };

  const handleDataAvailable = (event) => {
    if (event.data.size > 0) {
      setRecordedChunks(prev => prev.concat(event.data));
    }
  };

  useEffect(() => {
    if (isVerified && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      const videoBlob = new Blob(recordedChunks, { type: 'video/webm' });
      setvideofile(new File([videoBlob], "recording.webm", { type: 'video/webm' }));
    }
  }, [isVerified, isRecording, recordedChunks])

  const handleVideoOnPlay = () => {
    setInterval(async () => {
      
      if (videoRef.current && canvasRef.current) {
        const displaySize = { width: videoWidth, height: videoHeight };
        faceapi.matchDimensions(canvasRef.current, displaySize);
        
        
        const detections = await faceapi.detectAllFaces(videoRef.current, new faceapi.TinyFaceDetectorOptions())
                                         .withFaceLandmarks()
                                         .withFaceDescriptors();

        const resizedDetections = faceapi.resizeResults(detections, displaySize);
        
        faceapi.draw.drawDetections(canvasRef.current, resizedDetections);
        faceapi.draw.drawFaceLandmarks(canvasRef.current, resizedDetections);
        
        if (detections.length === 2) {
          const faceMatcher = new faceapi.FaceMatcher(detections[0]);

          const bestMatch = faceMatcher.findBestMatch(detections[1].descriptor);
          if (bestMatch.distance <= 0.5) {
            setIsVerified(true);
            
          }

        }
        
      }
    }, 100);
  };
  const handleVerifyFace = async () => {
    const detections = await faceapi.detectAllFaces(videoRef.current, new faceapi.TinyFaceDetectorOptions())
      .withFaceLandmarks()
      .withFaceDescriptors();

    if (detections.length >= 2) {
      const faceMatcher = new faceapi.FaceMatcher(detections);
      let matchFound = false;
      detections.forEach((det, index) => {
        const bestMatch = faceMatcher.findBestMatch(detections[index].descriptor);
        if (bestMatch.distance < 0.5) { // Adjust this value based on required precision
          matchFound = true;
        }
      });

      if (matchFound) {
        setIsVerified(true);
        setShowVideo(false);
        videoRef.current.srcObject.getTracks().forEach(track => track.stop());
      } else {
        alert("No matching faces found. Please try again.");
      }
    } else {
      alert("Please ensure two faces are visible in the camera.");
    }
  };

    return (
    <div>
      {showVideo ? (
            <>
              <video ref={videoRef} autoPlay="autoplay" playsInline width="720" height="560" />
              <button onClick={handleVerifyFace} className="bg-green-500 text-white px-4 py-2 rounded mt-4">Verify Faces</button>
            </>
          ) :  (
              null
            )}
            {captureVideo && modelsLoaded && (
              <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
                <video ref={videoRef} height={videoHeight} width={videoWidth} onPlay={handleVideoOnPlay} style={{ borderRadius: '10px' }} />
                <canvas ref={canvasRef} style={{ position: 'absolute' }} />
              </div>
            )}
      <div className="min-h-screen flex flex-col justify-center items-center bg-white">
        {/* The main parent div */}
        
        <div className="bg-white p-10 rounded items-center text-center">
        
        <Link to='/home'> <img src={Cyber} alt="Logo" className="w-[200px] h-[200px] mx-16 " /> </Link>
          <h1 className="text-5xl font-semibold m-6 ">Sign up</h1>
          
          <div className="bg-white p-10 rounded shadow-md">
          <form onSubmit={handleSave}>
          <div className="mb-4 relative">
              <label htmlFor="name" className="text-gray-600 placeholder-gray-300 text-xs font-semibold absolute top-[-5px] left-3 bg-white p-1">
                First Name
              </label>
              <input
                type="text"
                placeholder="John"
                className="w-full px-3 py-3 my-2 text-center border border-gray-900 rounded focus:outline-none focus:border-[#DE4646]"
                onChange={(e) => handleFirstnameChange(e.target.value)}
              />
            </div>
            <div className="mb-4 relative">
              <label htmlFor="name" className="text-gray-600 placeholder-gray-300 text-xs font-semibold absolute top-[-5px] left-3 bg-white p-1">
                Last Name
              </label>
              <input
                type="text"
                placeholder="Doe"
                className="w-full px-3 py-3 my-2 text-center border border-gray-900 rounded focus:outline-none focus:border-[#DE4646]"
                onChange={(e) => handleLastnameChange(e.target.value)}
              />
            </div>
            <div className="mb-4 relative">
              <label htmlFor="name" className="text-gray-600 placeholder-gray-300 text-xs font-semibold absolute top-[-5px] left-3 bg-white p-1">
                Email
              </label>
              <input
                type="email"
                placeholder="Example@gmail.com"
                className="w-full px-3 py-3 my-2 text-center border border-gray-900 rounded focus:outline-none focus:border-[#DE4646]"
                onChange={(e) => handleNameChange(e.target.value)}
              />
            </div>
            <div className="mb-4 relative">
              <label htmlFor="name" className="text-gray-600 placeholder-gray-300 text-xs font-semibold absolute top-[-5px] left-3 bg-white p-1">
                Security Email
              </label>
              <input
                type="email"
                placeholder="Example2@gmail.com"
                className="w-full px-3 py-3 my-2 text-center border border-gray-900 rounded focus:outline-none focus:border-[#DE4646]"
                onChange={(e) => handleSecurityEmailChange(e.target.value)}
              />
            </div>
            <div className="mb-4 relative">
              <label htmlFor="name" className="text-gray-600 placeholder-gray-300 text-xs font-semibold absolute top-[-5px] left-3 bg-white p-1">
                Password
              </label>
              <input
                type="password"
                placeholder = "Password"
                className="w-full px-3 py-3 my-2 text-center border border-gray-900 rounded focus:outline-none focus:border-[#DE4646]"
                onChange={(e) => handlePasswordChange(e.target.value)}
              />
            </div>
            <div className="mb-4 relative">
              <label htmlFor="name" className="text-gray-600 placeholder-gray-300 text-xs font-semibold absolute top-[-5px] left-3 bg-white p-1">
                Confirm Password
              </label>
              <input
                type="password"
                id="password"
                name="password"
                placeholder = "Password"
                className="w-full px-3 py-3 my-2 text-center border border-gray-900 rounded focus:outline-none focus:border-[#DE4646]"
                onChange={(e) => handleConfirmPasswordChange(e.target.value)}
              />
            </div>
            <div className="mb-4 relative">
            <label htmlFor="password" className="text-gray-600 placeholder-gray-300 text-xs font-semibold absolute top-[-5px] left-3 bg-white p-1">
              Account Type
            </label>
            <select
              className="w-full px-3 py-3 my-2 text-center border border-gray-900 rounded focus:outline-none focus:border-[#DE4646]"
              onChange={(e) => handleTypeChange(e.target.value)}
            >
              <option value="" disabled selected>Account Type</option>
              <option value="Personal">Personal</option>
              <option value="Org">Organisational</option>
            </select>
          </div>
            <button
              //disabled={!isVerified}
              type="submit"
              className="bg-[#DE4646] ml-[0px] text-white w-full px-3 py-2 mt-7 rounded hover:bg-red-400"
            >
              Submit
            </button>
          </form>
          <div className='mt-6'>
          <p className='w-[250px] bg-gray-100 p-2 rounded mb-6'>
                Please verify your face to proceed. Click Open Webcam and scan your face with a photo ID next to your face to verify your identity.
              </p>
              <button onClick={startVideo} className="bg-[#DE4646] hover:bg-red-400 text-white px-4 py-2 rounded w-full">
                    Open Webcam
              </button>
              
              <p className='mt-5'>
                  Already have an Account? <Link tp="/login" className='text-red-500 hover:text-red-800'>Login.</Link>
              </p>
          </div>
        </div>
        </div>
      </div>
      
    </div>
  );
}


export default Signup;
