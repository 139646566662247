import React, {useState, useEffect} from 'react';
import { CgMenuBoxed } from 'react-icons/cg'
import { AiOutlineCloseSquare } from 'react-icons/ai'
import Cyber from '../assets/Cyber.png';
import { Link, useNavigate } from 'react-router-dom'
import { RiHome4Line } from "react-icons/ri";
import { MdOutlineDashboard } from "react-icons/md";
import { HiOutlineLogout, HiOutlineUser, HiOutlineDocument, HiOutlineFolder  } from "react-icons/hi";
import { HiOutlineLogin } from "react-icons/hi";
import { RxPencil2 } from "react-icons/rx";
import { AiOutlineDatabase } from "react-icons/ai";
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const AuthNavbar = () => {


  const [nav, setNav] = useState(true);
  const [navFixed, setFix] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  // if(!token){
  //   navigate('/login');
  // }
 

  const handleNav = () => {
    setNav(!nav);
  };

  useEffect(() => {
    const handleScroll = () => {
      // Get the scroll position
      const scrollY = window.scrollY;

      // Determine if the navbar should be fixed or not
      if (scrollY > 0) {
        setFix(true);
      } else {
        setFix(false);
      }
    };

    // Add a scroll event listener to handle the scrolling
    window.addEventListener('scroll', handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleLogout = () => {
    localStorage.clear();
   navigate('/login');
   toast.success("Logout Successful!", { position: 'top-center' });
  }




  if(token){
  return (
    <div className={`${ navFixed ? 'fixed z-50 top-0 left-0 w-full shadow-lg' : '' }
     bg-gray-300 py-4 h-20 flex justify-between border-b border-r-gray-900 bg-gradient-to-r from-white`}>
      {/* Left Section */}
      <div className="flex items-center text-[#DE4646] ">
        {/* Add your left-side components here */}
        <Link to='/home'> <img src={Cyber} alt="Logo" className="w-20 h-20 ml-6 mr-3 " /> </Link>
        <h1 className=" text-3xl font-bold mx-5">IDENTIKEY</h1>
        <div onClick={handleNav} className="pt-8 mx-2">
          {!nav ? <AiOutlineCloseSquare size={40} className="mb-1.5"/> : <CgMenuBoxed size={40} className="mb-2" />}
            <CgMenuBoxed size={30} className="pr-8" />
        </div>
        <div className={!nav ? "fixed left-0 top-20 z-50 w-[270px] h-full border-r border-r-gray-300 bg-gray-100 ease-in-out duration-500" : "fixed left-[-100%]"}>
            <ul className="justify-center">
              <Link to="/assets">
              <ul className="hover:bg-white">
                <li className="uppercase font-semibold border-t border-gray-300 p-4 pt-10">Assets < HiOutlineDocument className='inline-block ml-1 mt-[-3px]'/></li>
                <li className='border-b border-gray-300 text-sm px-4 text-[#8C8888] pb-10'>you can view your assets or upload your assets here! via manual upload or by video upload.</li>
              </ul>
              </Link>
              <Link to="/dashboard">
              <ul className="hover:bg-white">
                <li className="uppercase font-semibold border-t border-gray-300 p-4 pt-10">Dashboard < MdOutlineDashboard className='inline-block ml-1 mt-[-3px]'/></li>
                <li className='border-b border-gray-300 text-sm px-4 text-[#8C8888] pb-10'>you can view your our services and tools here! </li>
              </ul>
              </Link>
              <Link to='/profile'> <a>  
              <ul className="hover:bg-white">
                <li className="uppercase font-semibold pt-10 p-4 px-4">Profile < HiOutlineUser className='inline-block ml-1 mt-[-3px]'/></li>
                <li className='border-b border-gray-300 text-sm px-4 text-[#8C8888] pb-10'>You can view your account details here, aswell as add new addresses and contact details.</li>
              </ul>
              </a>
              </Link>
              <Link to='/upload'> <a>
              <ul className="hover:bg-white">
                <li className="uppercase font-semibold pt-10 p-4">Upload < HiOutlineFolder className='inline-block ml-1 mt-[-3px]'/></li>
                <li className='border-b border-gray-300 text-sm px-4 text-[#8C8888] pb-10'>you can view your packages of assets, or search for a package you've been provided access to via the package iD.</li>
              </ul>
              </a>
              </Link>
              <a onClick={handleLogout}>
              <ul className="hover:bg-white">
                <li className="uppercase font-semibold pt-10 p-4">Logout < HiOutlineLogout className='inline-block ml-1 mt-[-3px]'/></li>
                <li className='border-b border-gray-300 text-sm px-4 text-[#8C8888] pb-10'>you can Logout of your account here.</li>
              </ul>
              </a>
            </ul>
        </div>
      </div>

      {/* Middle Section */}
      <div className="flex items-center">
        {/* Add your middle components here */}
      </div>

      {/* Right Section */}
      <div className="flex items-center space-x-4 text-[#DE4646]">
        {/* Add your right-side components here */}
        <ul className="flex text-lg bg-opacity-20 bg-white rounded-2xl mr-3">
            <Link to='/home.auth'><li className="p-4 pr-8 hover:text-red-800"><a className='mt-3'>Home </a><RiHome4Line className="inline-block ml-2 h-6 w-6"/></li></Link>
            <Link to='/dashboard'><li className="p-4 pr-8 hover:text-red-800"><a className='mt-3'>Dashboard </a><MdOutlineDashboard className="inline-block ml-2 h-5 w-6" /></li></Link>
            <Link to='/profile'>  <li className="p-4 pr-8 float-right hover:text-red-800"><a className='mt-3'>Profile</a> <HiOutlineUser className="inline-block ml-2 h-5 w-6" /></li></Link>
            <Link to='/login'>  <li className="p-4 pr-8 float-right hover:text-red-800" onClick={handleLogout}><a className='mt-3'>Logout</a> <HiOutlineLogout className="inline-block ml-2 h-5 w-6" /></li></Link>
        </ul>
      </div>
    </div>

  )
} else {
  return (
    <div className={`${ navFixed ? 'fixed z-50 top-0 left-0 w-full' : '' }
    bg-gray-300 py-4 h-20 flex justify-between border-b border-r-gray-900 bg-gradient-to-r from-white`}>
     {/* Left Section */}
     <div className="flex items-center text-[#DE4646] ">
       {/* Add your left-side components here */}
       <Link to='/home'> <img src={Cyber} alt="Logo" className="w-20 h-20 ml-6 mr-3 " /> </Link>
       <h1 className=" text-3xl font-bold pt-2 mx-4">IDENTIKEY</h1>
       <div onClick={handleNav} className="pt-8 mx-2">
         {!nav ? <AiOutlineCloseSquare size={40}/> : <CgMenuBoxed size={40} />}
           <CgMenuBoxed size={30} className="pr-8" />
       </div>
       <div className={!nav ? "fixed left-0 z-50 top-20 w-[270px] h-full border-r border-r-gray-300 bg-gray-100 ease-in-out duration-500" : "fixed left-[-100%]"}>
           <ul className="justify-center">
             <ul className="hover:bg-white">
               <a href="/home#footer"> 
                 <li className="uppercase font-semibold border-t border-gray-300 p-4">Services <AiOutlineDatabase className='inline-block ml-1 mt-[-3px]'/></li>
                 <li className='border-b border-gray-300 text-sm px-4 text-[#8C8888] pb-4'>Find our wide range of services we offer here!</li>
               </a>
             </ul>
             <Link to="/login">
             <ul className="hover:bg-white">
               <li className="uppercase font-semibold p-4">Login <HiOutlineLogin className='inline-block ml-1 mt-[-3px]'/></li>
               <li className='border-b border-gray-300 text-sm px-4 text-[#8C8888] pb-4'>You can login to your account here.</li>
             </ul>
             </Link>
             <Link to="/signup">
             <ul className="hover:bg-white">
               <li className="uppercase font-semibold p-4">Sign Up <RxPencil2 className='inline-block ml-1 mt-[-3px]'/></li>
               <li className='border-b border-gray-300 text-sm px-4 text-[#8C8888] pb-4'>if you don't have an account already sign up and register here!</li>
             </ul>
             </Link>
           </ul>
       </div>
     </div>

     {/* Middle Section */}
     <div className="flex items-center">
       {/* Add your middle components here */}
     </div>

     {/* Right Section */}
     <div className="flex items-center space-x-4 text-[#DE4646]">
       {/* Add your right-side components here */}
       <ul className="flex text-lg bg-opacity-20 bg-white mr-3 rounded-2xl">
           <Link to="/home"><li className="p-4 pl-8 mr-4 pr-7 float-right hover:text-red-800">Home <RiHome4Line className="inline-block ml-2 h-6 w-6"/></li> </Link>
           <a href="/home#footer"><li className="p-4 pr-8 float-right hover:text-red-800">Services <AiOutlineDatabase className="inline-block ml-2 h-6 w-6"/></li></a>
           <Link to='/signup'><li  className='p-4 pr-8 float-right hover:text-red-800'>Sign Up <RxPencil2 className="inline-block ml-2 h-5 w-6" /></li></Link>
         <Link to='/login'><li  className='p-4 pr-8 float-right  hover:text-red-800'>Login <HiOutlineLogin className="inline-block ml-2 h-6 w-6"/></li></Link>
       </ul>
     </div>
   </div>
  );
}
};

export default AuthNavbar;