import React from 'react';
import { Link } from 'react-router-dom';

const Tools = () => {
  return (
    <div id='cards' className='w-full py-8 px-4 bg-gray-100'>
      <div className='max-w-[2400px] ml-[180px] mx-auto grid grid-cols-1 md:grid-cols-3'>
        <div className='w-[70%] shadow-xl p-8 m-5 rounded-lg bg-white hover:scale-105 duration-300'>
          <h1 className='text-2xl text-[#DE4646] font-bold px-4 py-4'>View Assets</h1>
          <div className='px-4 font-medium'>
            <p className='py-2 border-b mx-4 my-4'>
              Here at Cyber Centre we offer secure holding of sensitive data as a service, our guarantee is once your data has been uploaded it is unaccessible from the rest of the world unless you share your assets via a package.
            </p>
          </div>
          <Link to="/assets"><button className='px-5 py-2 mx-4 rounded-full'>My Assets</button></Link>
        </div>
        <div className='w-[70%] shadow-xl p-8 m-5 rounded-lg bg-white hover:scale-105 duration-300'>
          <h1 className='text-2xl text-[#DE4646] font-bold px-4 py-4'>Upload Assets</h1>
          <div className='font-medium'>
            <p className='py-2 border-b px-4 mx-4 my-4'>
              Here at Cyber Centre we offer secure holding of sensitive data as a service, our guarantee is once your data has been uploaded it is unaccessible from the rest of the world unless you share your assets via a package.
            </p>
          </div>
          <Link to="/upload"><button className='px-5 py-2 mx-4 rounded-full'>Upload</button></Link>
        </div>
        <div className='w-[70%] shadow-xl p-8 m-5 rounded-lg bg-white hover:scale-105 duration-300'>
          <h1 className='text-2xl font-bold text-[#DE4646] px-4 py-4'>Request Packages</h1>
          <div className='px-4 font-medium'>
            <p className='py-2 border-b mx-4 my-4'>
              Here at Cyber Centre we offer secure holding of sensitive data as a service, our guarantee is once your data has been uploaded it is unaccessible from the rest of the world unless you share your assets via a package.
            </p>
          </div>
          <Link to="/assets"><button className='px-5 py-2 mx-4 rounded-full'>My Packages</button></Link>
        </div>
      </div>
    </div>
  );
};

export default Tools;