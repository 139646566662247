
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Tabs } from "flowbite-react";
import { HiClipboardList, HiUserCircle, HiShare, HiFlag } from "react-icons/hi";
import { MdAddBox } from "react-icons/md";
import { Link } from 'react-router-dom';
import { FiEdit } from "react-icons/fi";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { AiOutlineDelete } from "react-icons/ai";
const backendIP = process.env.BACKEND_IP;



const Profile = () => {

    const [userData, setUserdata] = useState(null);
    const [addressData, setAddressData] = useState(null);
    const [contactData, setContactData] = useState(null);
    const [ChildData, setChildData] = useState(null);

    const [isEditing, setIsEditing] = useState(false);
    const [editingIndex, setEditingIndex] = useState(-1);
    const [isAdding, setIsAdding] = useState(false);

    const [countries, setCountries] = useState([]);
    const [addressTypes, setAddressTypes] = useState([]);
    const [contactTypes, setContactTypes] = useState([]);

    const [Updatedaddress, setUAddress] = useState({
        street: '',
        city: '',
        state: '',
        postcode: '',
        country: '',
        addresstype: '',
    });


    const [newaddress, setNewAddress] = useState({
        street: '',
        city: '',
        state: '',
        postcode: '',
        country: '',
        addresstype: '',

    });

    const [newcontact, setNewContact] = useState({
        number: '',
        contacttype: '',
        contactcode: '',
        notes: ''
    });

    const [Updatedcontact, setUContact] = useState({
        number: '',
        contacttype: '',
        contactcode: '',
        notes: ''
    });

        // Function to handle the click event of the edit button
    const handleEditClick = (index) => {
        // Set the editingIndex state to the index of the clicked entry
        setEditingIndex(index);
        // Set isEditing to true to enter editing mode
        setIsEditing(true);
    };

    // Function to handle the click event of the save button
    const handleSaveClick = () => {
        // You can perform validation here before saving
        setEditingIndex(-1); // Reset editingIndex to -1 to close the editing section
        // Handle saving the address data
        setIsEditing(false); // Set isEditing to false to exit editing mode
        // Handle saving the address data
    };


    const handleAddClick = () => {
        setIsAdding(true);
      };

    const handleSaddClick = () => {
       // You can perform validation here before saving
       setIsAdding(false);
       
       // Handle saving the address data
    };

    const handleChangeAddress = (e) => {
       setUAddress({
         ...Updatedaddress,
         [e.target.name]: e.target.value
       });
       //console.log(Updatedaddress);
    };

    const handleNewAddress = (e) => {
        setNewAddress({
          ...newaddress,
          [e.target.name]: e.target.value
        });
     };

    const handleChangeContact = (e) => {
        setUContact({
          ...Updatedcontact,
          [e.target.name]: e.target.value
        });
     };

     const handleNewContact = (e) => {
        setNewContact({
          ...newcontact,
          [e.target.name]: e.target.value
        });
     };

    useEffect(() => {
      // Fetch data from backend when component mounts
      fetchDataFromBackend();
      fetchADataFromBackend();
      fetchCDataFromBackend();
      //fetchCountries();
      //fetchAddressType();
      //fetchContactType();
      fetchChild();
    }, []);
  
    const fetchDataFromBackend = () => {
      // Get token from local storage
      const token = localStorage.getItem('token');
  
      // Check if token exists
      if (token) {
        // Set Authorization header with the token
        const config = {
          headers: {
            Authorization: `Bearer ${token}`
          }
        };
  
        // Make a GET request to fetch data from backend
        axios.get(`${backendIP}/api/Auth/userinfo`, config)
          .then((response) => {
            setUserdata(response.data);
            //console.log(response.data);
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      } else {
        console.error('Token not found in local storage');
      }
    };

    const fetchADataFromBackend = () => {
        // Get token from local storage
        const token = localStorage.getItem('token');
    
        // Check if token exists
        if (token) {
          // Set Authorization header with the token
          const config = {
            headers: {
              Authorization: `Bearer ${token}`
            }
          };
    
          // Make a GET request to fetch data from backend
          axios.get(`${backendIP}/api/Profile/userAddresses`, config)
            .then((response) => {
              setAddressData(response.data);
              console.log(response.data);
            })
            .catch((error) => {
              console.error('Error fetching data:', error);
            });
        } else {
          console.error('Token not found in local storage');
        }
      };

      const fetchCDataFromBackend = () => {
        // Get token from local storage
        const token = localStorage.getItem('token');
    
        // Check if token exists
        if (token) {
          // Set Authorization header with the token
          const config = {
            headers: {
              Authorization: `Bearer ${token}`
            }
          };
    
          // Make a GET request to fetch data from backend
          axios.get(`${backendIP}/api/Profile/userContacts`, config)
            .then((response) => {
              setContactData(response.data);
              console.log(response.data);
            })
            .catch((error) => {
              console.error('Error fetching data:', error);
            });
        } else {
          console.error('Token not found in local storage');
        }
      };

      const handleSave = (event) => {
        event.preventDefault();

        
        // Get token from local storage
        const token = localStorage.getItem('token');
        
        const data = {
            addressid: "0",            
            addresstype: newaddress.addresstype,
            address1: newaddress.street,
            city: newaddress.city,
            postcode: newaddress.postcode,
            state: newaddress.state,
            country: newaddress.country          
        };


        console.log(data);
        
        if (token) {
            // Set Authorization header with the token
            const config = {
              headers: {
                Authorization: `Bearer ${token}`
              }
            };
      
            // Make a GET request to fetch data from backend
            axios.post(`${backendIP}/api/Profile/InsertuserAddress`, data, config)
              .then(() => {
                console.log("Address added successfully");
                handleSaddClick();
              })
              .catch(error => {
                if (error.response && error.response.data && error.response.data.errors) {
                  const validationErrors = error.response.data.errors;
                  for (const field in validationErrors) {
                    if (validationErrors.hasOwnProperty(field)) {
                      console.log(`Error in ${field}: ${validationErrors[field]}`);
                    }
                  }
                } else if (error.response) {
                  console.log('Error status:', error.response.status);
                  console.log('Error data:', error.response.data);
                } else {
                  console.log('Error:', error.message);
                }})
          } else {
            console.error('Token not found in local storage');
            handleSaddClick();
          }
         
      };

      const handleUpdate = (event) => {
        event.preventDefault();
        
        // Get token from local storage
        const token = localStorage.getItem('token');
        

        const data = {
          pubAddress:{
            addressid: parseInt(addressData[editingIndex].addressId),
            addresstype: Updatedaddress.addresstype,
            address1: Updatedaddress.street,
            city: Updatedaddress.city,
            postcode: Updatedaddress.postcode,
            state: Updatedaddress.state,
            country: Updatedaddress.country
          }
        };

        
        if (token) {
            // Set Authorization header with the token
            const config = {
              headers: {
                Authorization: `Bearer ${token}`
              }
            };

            console.log(data);
      
            // Make a POST request to send data to the backend
            axios.post(`${backendIP}/api/Profile/UpdateuserAddress`, data, config)
              .then(() => {
                console.log("Address added successfully");
                handleSaveClick();
              })
              .catch((error) => {
                console.error('Error fetching data:', error);
                
              });
          } else {
            console.error('Token not found in local storage');
          }
         
      };

    const handleNew = (event) => {
        event.preventDefault();
        
        // Get token from local storage
        const token = localStorage.getItem('token');
        
        const data = {
            contact: {
                contactdetail: newcontact.number,
                contacttype: newcontact.contacttype,
                contactcode: newcontact.contactcode,
                notes: newcontact.notes
            }
        };
        
        if (token) {
            // Set Authorization header with the token
            const config = {
              headers: {
                Authorization: `Bearer ${token}`
              }
            };
      
            // Make a GET request to fetch data from backend
            axios.post(`${backendIP}/api/Profile/InsertuserContact`, data, config)
              .then(() => {
                console.log("Address added successfully");
                handleSaddClick();
                
              })
              .catch((error) => {
                console.error('Error fetching data:', error);
                handleSaddClick();
              });
          } else {
            console.error('Token not found in local storage');
            handleSaddClick();
          }
         
    };

    const handleCUpdate = (event) => {
        event.preventDefault();
        
        // Get token from local storage
        const token = localStorage.getItem('token');
        
        const data = {
            contact: {
                contactid: contactData[editingIndex].contactID,
                contactdetail: Updatedcontact.number,
                contacttype: Updatedcontact.contacttype,
                contactcode: Updatedcontact.contactcode,
                notes: Updatedcontact.notes
              }
        };

        
        
        if (token) {
            // Set Authorization header with the token
            const config = {
              headers: {
                Authorization: `Bearer ${token}`
              }
            };

            console.log(data);
      
            // Make a POST request to send data to the backend
            axios.post(`${backendIP}/api/Profile/UpdateuserContact`, data, config)
              .then(() => {
                console.log("Address added successfully");
                handleSaveClick();
              })
              .catch((error) => {
                console.error('Error fetching data:', error);
                
              });
          } else {
            console.error('Token not found in local storage');
            
          }
         
      };

      const fetchCountries = () => {
        axios.get(`${backendIP}/api/Profile/LocationsLookup`).then(response => {
            // Update the state with the fetched data
            setCountries(response.data);
            //console.log(countries);
          })
          .catch(error => {
            console.error('Error fetching country data:', error);
        });
        };

        const fetchAddressType = () => {
            axios.get(`${backendIP}/api/Profile/AddressTypeLookup`).then(response => {
                // Update the state with the fetched data
                setAddressTypes(response.data);
                console.log(response.data);
                //console.log(addressTypes);
              })
              .catch(error => {
                console.error('Error fetching country data:', error);
            });
        };
        
        const fetchContactType = () => {
               axios.get(`${backendIP}/api/Profile/ContactTypeLookup`).then(response => {
                   // Update the state with the fetched data
                   setContactTypes(response.data);
                   //console.log(contactTypes);
                })
                .catch(error => {
                   console.error('Error fetching country data:', error);
               });
        };

        const fetchChild = () => {

          // Get token from local storage
          const token = localStorage.getItem('token');

          if (token) {
            // Set Authorization header with the token
            const config = {
              headers: {
                Authorization: `Bearer ${token}`
              }
            };

          axios.get(`${backendIP}/api/Profile/ChildAccounts`, config).then(response => {
              // Update the state with the fetched data
              setChildData(response.data);
              console.log(ChildData);
           })
           .catch(error => {
              console.error('Error fetching country data:', error);
          });
        }
      };

    return (
            <div class=" w-full h-screen pattern-wavy pattern-gray-200 pattern-bg-white pattern-size-6 pattern-opacity-100">
                <div className="container h-screen w-full flex justify-center items-center">

                    <div className="rounded-3xl ml-[300px] h-[700px] w-[600px]">

                        <Tabs aria-label="Tabs with icons" style="default" className='w-[510px] bg-white'>
                            <Tabs.Item active title="Profile" icon={HiUserCircle}>
                            <div className="rounded-3xl shadow-lg bg-[#FFF4F4] h-[700px] w-[1200px] mt-[-20px] ml-[-20px]">

                                <div className="bg-white h-[100px] rounded-t-3xl flex pt-5">
                                    
                                    <h1 className="pt-4 pl-6 text-[#DE4646] text-3xl font-semibold">Profile :</h1>
                                </div>

                                <div className="bg-[#FFF4F4] rounded-3xl  pt-5">
                                    
                                    <form className="ml-10">
                                        <div className="grid grid-cols-10">
                                            <div className="w-[250px] col-span-7">
                                                <label htmlFor="name" className="mx-3 my-2 text-[#E17272]">
                                                Name :
                                                </label>
                                                <input
                                                type="text"
                                                id="name"
                                                name="name"
                                                value= {userData ? userData.firstName + ' ' + userData.lastName : 'John Doe'} 
                                                disabled class="w-[85%] h-[40px] px-3 py-3 my-2 mx-3 text-[#E17272] placeholder-[#E79494] bg-[#F6C1C1] rounded  focus:outline-none focus:border-[#DE4646]"
                                                />

                                            </div>

                                            <div className="w-[250px] col-span-7">
                                                <label htmlFor="Email" className="mx-3 my-2 text-[#E17272]">
                                                Email :
                                                </label>
                                                <input
                                                type="Email"
                                                id="Email"
                                                name="name"
                                                value= {userData ? userData.email : 'John.Doe@Gmail.com'}
                                                disabled class="w-[85%] h-[40px] px-3 py-3 my-2 mx-3 text-[#E17272] placeholder-[#E79494] bg-[#F6C1C1] rounded  focus:outline-none focus:border-[#DE4646]"
                                                />

                                            </div>

                                            <div className="w-[350px] col-span-10">
                                                
                                                <label htmlFor="Address" className="mx-3 my-2 text-[#E17272] text-sm">
                                                Country:
                                                </label>
                                                <input
                                                type="Country"
                                                id="Country"
                                                name="Country"
                                                value=""
                                                disabled className="w-[85%] h-[40px] px-3 py-3 my-2 mx-3 text-[#E17272] placeholder-[#E79494] bg-[#F6C1C1] rounded  focus:outline-none focus:border-[#DE4646]"
                                                />
                                                <label htmlFor="State" className="mx-3 my-2 text-[#E17272] text-sm">
                                                State :
                                                </label>
                                                <input
                                                type="State"
                                                id="State"
                                                name="State"
                                                value=""
                                                disabled className="w-[85%] h-[40px] px-3 py-3 my-2 mx-3 text-[#E17272] placeholder-[#E79494] bg-[#F6C1C1] rounded  focus:outline-none focus:border-[#DE4646]"
                                                />
                                                <label htmlFor="DOB" className="mx-3 my-2 text-[#E17272] text-sm">
                                                Date of Birth:
                                                </label>
                                                <input
                                                type="DOB"
                                                id="DOB"
                                                name="DOB"
                                                value=""
                                                disabled className="w-[85%] h-[40px] px-3 py-3 my-2 mx-3 text-[#E17272] placeholder-[#E79494] bg-[#F6C1C1] rounded  focus:outline-none focus:border-[#DE4646]"
                                                />
                                                <label htmlFor="Account Type" className="mx-3 my-2 text-[#E17272] text-sm">
                                                Account Type:
                                                </label>
                                                <input
                                                type="Account Type"
                                                id="Account Type"
                                                name="Account Type"
                                                value=""
                                                disabled className="w-[85%] h-[40px] px-3 py-3 my-2 mx-3 text-[#E17272] placeholder-[#E79494] bg-[#F6C1C1] rounded  focus:outline-none focus:border-[#DE4646]"
                                                />
                                            </div>
                                            
                                        </div>
                                    </form>


                                </div>

                            </div>
                            </Tabs.Item>
                            <Tabs.Item title="Linked Accounts" icon={HiShare}>
                            <div className="rounded-3xl shadow-lg bg-[#FFF4F4] h-[700px] w-[1200px] mt-[-20px] ml-[-20px]">

                                <div className="bg-white h-[100px] rounded-t-3xl flex pt-5">
                                    
                                    <h1 className="pt-4 pl-6 text-[#DE4646] text-3xl font-semibold">Linked Accounts :</h1>
                                </div>

                                <div className="bg-[#FFF4F4] rounded-3xl  pt-5">
                                    
                                
                                        <div className="grid grid-cols-2  ">

                                            <div className='col-span-1 mt-3 pb-10 border-r-2 border-red-200 overflow-auto'>
                                                
                                                <h1 className="ml-7 mb-4 pt-4 text-[#DE4646] text-xl">Linked Accounts :</h1>
                                                
                                                  {ChildData ? (
                                                    <form className="ml-10 grid grid-cols-1">
                                                      {ChildData.map((item, index) => (
                                                        <div key={index} className="w-[250px] col-span-1">
                                                        <label htmlFor="name" className="mx-3 my-2 text-[#E17272]">
                                                        Child Account ID :
                                                        </label>
                                                        <input
                                                        type="text"
                                                        id="name"
                                                        name="name"
                                                        value= {ChildData ? item.child : ''} 
                                                        disabled class="w-[85%] h-[40px] px-3 py-3 my-2 mx-3 text-[#E17272] placeholder-[#E79494] bg-[#F6C1C1] rounded  focus:outline-none focus:border-[#DE4646]"
                                                        />
                                                    </div>
                                                      ))}
                                                    </form>
                                                  ) : (
                                                    <p className="text-red-700 ml-7">No child accounts linked.</p>
                                                  )}
                                            </div>
                                            <div className='col-span-1'>
                                                <div>
                                                    <h1 className="pl-10 pt-5 text-2xl text-[#E17272]">Create & Link a new Account</h1>
                                                    <p className="pl-10 text-[#E17272]" >You can create and link a new account here.</p>
                                                    <Link to="/linked"><MdAddBox className=" h-8 w-8 ml-10 fill-[#E17272] hover:fill-red-700"/></Link>
                                                </div>
                                            </div>
                                            
                                        </div>
                                </div>

                            </div>
                            </Tabs.Item>
                            <Tabs.Item title="Contacts" icon={HiClipboardList}>
                            <div className="rounded-3xl shadow-lg bg-[#FFF4F4] h-[700px] w-[1200px] mt-[-20px] ml-[-20px]">

                                <div className="bg-white h-[100px] rounded-t-3xl flex pt-5">
                                    
                                    <h1 className="pt-4 pl-6 text-[#DE4646] text-3xl font-semibold">Contacts :</h1>
                                </div>

                                <div className="bg-[#FFF4F4] rounded-3xl  pt-5  w-[100%] h-[75%]">
                                <div className="w-[100%] h-[75%] overflow-auto">
                                {contactData && Array.isArray(contactData) && (
                                 contactData.map((contact, index) => (
                                <form key={index} className="w-[100%] h-[75" onSubmit={handleCUpdate}>
                                            {isEditing && editingIndex === index ? (
                                                <>
                                                <div className="ml-5 grid grid-cols-10">
                                                    <div className="col-span-2">
                                                        <label className="mx-3 my-2 text-[#E17272]">Contact Type:</label>
                                                        <br/>
                                                        <select
                                                        className="w-[90%] h-[40px] my-2 mx-3 text-[#E17272] placeholder-[#E79494] focus:outline-none focus:border-[#DE4646]"
                                                        type="text"
                                                        name="contacttype"
                                                        value={Updatedcontact.contacttype}
                                                        onChange={handleChangeContact}
                                                        >
                                                            <option value="" disabled selected>Contact Type</option>
                                                            <option value="MB1" >Mobile</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-span-2">
                                                        <label className="mx-3 my-2 text-[#E17272]">Number:</label>
                                                        <br/>
                                                        <input
                                                        className="w-[90%] h-[40px] px-3 py-3 my-2 mx-3 text-[#E17272] placeholder-[#E79494] focus:outline-none focus:border-[#DE4646]" 
                                                        type="tel"                                 
                                                        name="number"
                                                        value={Updatedcontact.number}
                                                        onChange={handleChangeContact}
                                                        />
                                                    </div>
                                                    <div className="col-span-2">
                                                        <label className="mx-3 my-2 text-[#E17272]">Contact Code:</label>
                                                        <br/>
                                                        <input
                                                        className="w-[90%] h-[40px] px-3 py-3 my-2 mx-3 text-[#E17272] placeholder-[#E79494] focus:outline-none focus:border-[#DE4646]"
                                                        type="text"
                                                        name="contactcode"
                                                        value={Updatedcontact.contactcode}
                                                        onChange={handleChangeContact}
                                                        />
                                                    </div>
                                                    
                                                    <div className="col-span-2 content-center p-4 mt-5">
                                                    <button type="submit" className="bg-transparent border-0 hover:bg-transparent hover:fill-red-800"><FiEdit/></button>
                                                    <button className="ml-3 font-bold bg-transparent border-0 hover:bg-transparent hover:fill-red-800"><AiOutlineDelete className="h-5 w-5"/></button>
                                                    <button className="ml-3 font-bold bg-transparent border-0 hover:bg-transparent hover:fill-red-800"><IoIosCloseCircleOutline className="h-5 w-5"/></button>
                                                    </div>
                                                    <div className="col-span-4">
                                                        <label className="mx-3 my-2 text-[#E17272]">Contact Notes:</label>
                                                        <br/>
                                                        <textarea
                                                        name="notes"
                                                        defaultValue={Updatedcontact.notes}
                                                        onChange={handleChangeContact}
                                                        className="w-[95%] px-3 py-3 my-2 mx-3 h-[60px] text-[#E17272] placeholder-[#E79494] bg-white  focus:outline-none focus:border-[#DE4646]"></textarea>
                                                    </div>
                                                    
                                                </div>
                                                </>
                                            ) : (
                                                <>
                                                <div className="grid grid-cols-5">
                                                    <div className="ml-5 col-span-5">
                                                        <label className="mx-3 my-2 text-[#E17272]">Contact: {index}</label>
                                                        <br/>
                                                        <input
                                                        type="text"
                                                        value={`${contact.contactType}`}
                                                        disabled class="w-[20%] h-[40px] ml-3 px-3 text-[#E17272] placeholder-[#E79494] bg-[#F6C1C1]  focus:outline-none focus:border-[#DE4646]"
                                                        />
                                                        <input
                                                        type="tel"
                                                        value={`${contact.contactDetail}`}
                                                        disabled class="w-[20%] h-[40px] px-3 py-3 my-2 mx-2 text-[#E17272] placeholder-[#E79494] bg-[#F6C1C1]  focus:outline-none focus:border-[#DE4646]"
                                                        />
                                                        <button className="bg-transparent hover:bg-transparent hover:fill-red-800" onClick={() => handleEditClick(index)}><FiEdit/></button>
                                                        </div>
                                                    <div className="col-span-2">
                                                        <textarea
                                                        defaultValue={`${contact.notes}`}
                                                        disabled 
                                                        className="w-full ml-8 h-[100px] text-[#E17272] placeholder-[#E79494] bg-[#F6C1C1]  focus:outline-none focus:border-[#DE4646]"></textarea>
                                                    </div>
                                                </div>
                                                
                                                </>
                                            )}
                                    </form>
                                    ))
                                )}
                                {/* Display a message if addressData is null or not an array */}
                                {!contactData && <p className="text-red-700 ml-7">No contact data available</p>}
                                {contactData && !Array.isArray(contactData) && <p className="text-red-700 ml-7">Invalid contact data format</p>}
                                </div>
                                    <div className=" h-[40%] rounded-b-3xl bg-[#Ffeaea]">
                                        <form onSubmit={handleNew} className='pt-3'>
                                            {isAdding ? (
                                                    <>
                                                    <button className="mx-2 font-bold bg-transparent border-0 hover:text-red-800 hover:bg-transparent hover:fill-red-800 float-right" onclick={handleSaddClick}>X</button>
                                                    <div className="ml-5 grid grid-cols-10">
                                                        <div className="col-span-2">                                                        
                                                            <label className="mx-3 my-2 text-[#E17272]">Contact Type:</label>
                                                            <br/>
                                                            <select
                                                            className="w-[90%] h-[40px] my-2 mx-3 text-[#E17272] placeholder-[#E79494] focus:outline-none focus:border-[#DE4646]"
                                                            type="text"
                                                            name="contacttype"
                                                            value={newcontact.contacttype}
                                                            onChange={handleNewContact}
                                                            >
                                                                <option value="" disabled selected>Contact Type</option>
                                                                <option value="MB1" >Mobile</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-span-2">
                                                            <label className="mx-3 my-2 text-[#E17272]">Number:</label>
                                                            <br/>
                                                            <input
                                                            className="w-[90%] h-[40px] px-3 py-3 my-2 mx-3 text-[#E17272] placeholder-[#E79494] focus:outline-none focus:border-[#DE4646]" 
                                                            type="tel"                                 
                                                            name="number"
                                                            value={newcontact.number}
                                                            onChange={handleNewContact}
                                                            />
                                                        </div>
                                                        <div className="col-span-2">
                                                            <label className="mx-3 my-2 text-[#E17272]">Contact Code:</label>
                                                            <br/>
                                                            <input
                                                            className="w-[90%] h-[40px] px-3 py-3 my-2 mx-3 text-[#E17272] placeholder-[#E79494] focus:outline-none focus:border-[#DE4646]"
                                                            type="text"
                                                            name="contactcode"
                                                            value={newcontact.contactcode}
                                                            onChange={handleNewContact}
                                                            />
                                                        </div>
                                                        
                                                        <div className="col-span-4 content-center p-4 mt-5">
                                                        <button type="submit" className="bg-transparent border-0 hover:bg-transparent hover:fill-red-800"><FiEdit/></button>
                                                        </div>
                                                        <div className="col-span-4">
                                                        <label className="mx-3 my-2 text-[#E17272]">Contact Notes:</label>
                                                        <br/>
                                                        <input
                                                        type="text"
                                                        name="notes"
                                                        onChange={handleNewContact}
                                                        className="w-[95%] px-3 py-3 my-2 mx-3 h-[60px] text-xs text-[#E17272] placeholder-[#E79494] bg-white  focus:outline-none focus:border-[#DE4646]"></input>
                                                        </div>
                                                        
                                                    </div>
                                                    </>
                                                ) : (
                                                    <>
                                                    <div className="ml-7">
                                                    <button type="reset" className="h-7 flex bg-transparent text-[#E17272] border-0 hover:bg-transparent hover:text-red-500 fill-[#E17272] hover:fill-red-500" onClick={handleAddClick}>Add Contact : <MdAddBox className="h-7 w-7 ml-2"/></button>
                                                    </div>
                                                    
                                                    </>
                                                )}
                                        </form>
                                        
                                    </div>
                                </div>
                                
                            </div>
                            </Tabs.Item>
                            <Tabs.Item title="Addresses" icon={HiFlag}>
                            <div className="rounded-3xl shadow-lg bg-[#FFF4F4] h-[700px] w-[1200px] mt-[-20px] ml-[-20px]">

                                <div className="bg-white h-[100px] rounded-t-3xl flex pt-5">
                                    
                                    <h1 className="pt-4 pl-6 text-[#DE4646] text-3xl font-semibold">Addresses :</h1>
                                </div>

                                <div className="bg-[#FFF4F4] rounded-3xl  pt-5 w-[100%] h-[75%]">
                                <div className="w-[100%] h-[75%] overflow-auto">
                                {addressData && Array.isArray(addressData) && (
                                 addressData.map((address, index) => (   
                                <form key={index} className="w-[100% ]" onSubmit={handleUpdate}>
                                            {isEditing && editingIndex === index  ? ( 
                                                <>
                                                <div className="ml-10 grid grid-cols-10">
                                                    
                                                    <div className="col-span-2">
                                                        <label className="mx-3 my-2 text-[#E17272]">Street:</label>
                                                        <br/>
                                                        <input
                                                        className="w-[90%] h-[40px] px-3 py-3 my-2 mx-3 text-[#E17272] placeholder-[#E79494] focus:outline-none focus:border-[#DE4646]"
                                                        type="text"
                                                        name="street"
                                                        value={Updatedaddress.street}
                                                        onChange={handleChangeAddress}
                                                        />
                                                    </div>
                                                    <div className="col-span-2">
                                                        <label className="mx-3 my-2 text-[#E17272]">City:</label>
                                                        <br/>
                                                        <input
                                                        className="w-[90%] h-[40px] px-3 py-3 my-2 mx-3 text-[#E17272] placeholder-[#E79494] focus:outline-none focus:border-[#DE4646]"                                                        type="text"
                                                        name="city"
                                                        value={Updatedaddress.city}
                                                        onChange={handleChangeAddress}
                                                        />
                                                    </div>
                                                    <div className="col-span-2">
                                                        <label className="mx-3 my-2 text-[#E17272]">State:</label>
                                                        <br/>
                                                        <input
                                                        className="w-[90%] h-[40px] px-3 py-3 my-2 mx-3 text-[#E17272] placeholder-[#E79494] focus:outline-none focus:border-[#DE4646]"
                                                        type="text"
                                                        name="state"
                                                        value={Updatedaddress.state}
                                                        onChange={handleChangeAddress}
                                                        />
                                                    </div>
                                                    <div className="col-span-2">
                                                        <label className="mx-3 my-2 text-[#E17272]">Country:</label>
                                                        
                                                        <br/>
                                                        <select
                                                        className="w-[90%] h-[40px]  my-2 mx-3 text-[#E17272] placeholder-[#E79494] focus:outline-none focus:border-[#DE4646]"
                                                        type="text"
                                                        name="country"
                                                        value={Updatedaddress.country}
                                                        onChange={handleChangeAddress}
                                                        >
                                                            <option value="" disabled selected>Country</option>
                                                            <option value="Australia">Australia</option>  
                                                        </select>
                                                    </div>
                                                    <div className="col-span-2 inline-flex p-4 mt-5">
                                                    <button type="submit" className="bg-transparent border-0 hover:bg-transparent hover:fill-red-800"><FiEdit/></button>
                                                    <button className="ml-3 font-bold bg-transparent border-0 hover:bg-transparent hover:fill-red-800"><AiOutlineDelete className="h-5 w-5"/></button>
                                                    <button className="ml-3 font-bold bg-transparent border-0 hover:bg-transparent hover:fill-red-800"><IoIosCloseCircleOutline className="h-5 w-5"/></button>
                                                    </div>
                                                    <div className="col-span-2">
                                                        <label className="mx-3 my-2 text-[#E17272]">Post Code:</label>
                                                        <br/>
                                                        <input
                                                        className="w-[90%] h-[40px] px-3 py-3 my-2 mx-3 text-[#E17272] placeholder-[#E79494] focus:outline-none focus:border-[#DE4646]"
                                                        type="text"
                                                        name="postcode"
                                                        value={Updatedaddress.postcode}
                                                        onChange={handleChangeAddress}
                                                        />
                                                    </div>
                                                    <div className="col-span-2">
                                                        <label className="mx-3 my-2 text-[#E17272]">Address Type:</label>
                                                        <br/>
                                                        <select
                                                        className="w-[90%] h-[40px]  my-2 mx-3 text-[#E17272] placeholder-[#E79494] focus:outline-none focus:border-[#DE4646]"
                                                        type="text"
                                                        name="addresstype"
                                                        value={Updatedaddress.addresstype}
                                                        onChange={handleChangeAddress}
                                                        >
                                                            <option value="" disabled selected>Address Type</option>
                                                            <option value="Postal">Postal</option>    
                                                        </select>
                                                    </div>
                                                </div>
                                                </>
                                            ) : (
                                                <>
                                                <div className="ml-10">
                                                    <label className="mx-3 my-2 text-[#E17272]">Address: {index}</label>
                                                    <br/>
                                                    <input
                                                    type="text"
                                                    value={`${address.addressType} : ${address.address1}, ${address.city}, ${address.state} ${address.postcode}, ${address.country}`}
                                                    disabled class="w-[40%] h-[40px] px-3 py-3 my-2 mx-3 text-[#E17272] placeholder-[#E79494] bg-[#F6C1C1]  focus:outline-none focus:border-[#DE4646]"
                                                    />
                                                    <button className="bg-transparent hover:bg-transparent hover:fill-red-800" onClick={() => handleEditClick(index)}><FiEdit/></button>
                                                    
                                                </div>
                                                
                                                </>
                                            )}
                                    </form>
                                ))
                            )}
                            {/* Display a message if addressData is null or not an array */}
                            {!addressData && <p className="text-red-700 ml-7">No address data available</p>}
                            {addressData && !Array.isArray(addressData) && <p className="text-red-700 ml-7">Invalid address data format</p>}
                            </div>                       
                                    <div className=" h-[40%] rounded-b-3xl bg-[#Ffeaea]">
                                        
                                        <form className='pt-3' onSubmit={handleSave}>
                                                {isAdding ? (
                                                    <>
                                                    <button className="mx-2 font-bold bg-transparent border-0 hover:text-red-800 hover:bg-transparent hover:fill-red-800 float-right" onclick={handleSaddClick}>X</button>
                                                    <div className="ml-51 grid grid-cols-10">
                                                        <div className="col-span-2">
                                                            <label className="mx-3 my-2 text-[#E17272]">Street:</label>
                                                            <br/>
                                                            <input
                                                            className="w-[90%] h-[40px] px-3 py-3 my-2 mx-3 text-[#E17272] placeholder-[#E79494] focus:outline-none focus:border-[#DE4646]"
                                                            type="text"
                                                            name="street"
                                                            value={newaddress.street}
                                                            onChange={handleNewAddress}
                                                            />
                                                        </div>
                                                        <div className="col-span-2">
                                                            <label className="mx-3 my-2 text-[#E17272]">City:</label>
                                                            <br/>
                                                            <input
                                                            className="w-[90%] h-[40px] px-3 py-3 my-2 mx-3 text-[#E17272] placeholder-[#E79494] focus:outline-none focus:border-[#DE4646]"                                                        type="text"
                                                            name="city"
                                                            value={newaddress.city}
                                                            onChange={handleNewAddress}
                                                            />
                                                        </div>
                                                        <div className="col-span-2">
                                                            <label className="mx-3 my-2 text-[#E17272]">State:</label>
                                                            <br/>
                                                            <input
                                                            className="w-[90%] h-[40px] px-3 py-3 my-2 mx-3 text-[#E17272] placeholder-[#E79494] focus:outline-none focus:border-[#DE4646]"
                                                            type="text"
                                                            name="state"
                                                            value={newaddress.state}
                                                            onChange={handleNewAddress}
                                                            />
                                                        </div>
                                                        <div className="col-span-2">
                                                            <label className="mx-3 my-2 text-[#E17272]">Country:</label>
                                                            <br/>
                                                            <select
                                                        className="w-[90%] h-[40px] my-2 mx-3 text-[#E17272] placeholder-[#E79494] focus:outline-none focus:border-[#DE4646]"
                                                        type="number"
                                                        name="country"
                                                        value={newaddress.country}
                                                        onChange={handleNewAddress}
                                                        >
                                                            <option value="" disabled selected>Country</option>
                                                            <option value="Australia">Australia</option>   
                                                        </select>
                                                        </div>
                                                        <div className="col-span-2 content-center p-4 mt-5">
                                                        <button type="submit" className="bg-transparent border-0 hover:bg-transparent hover:fill-red-800" ><MdAddBox className="h-7 w-7 fill-[#E17272] hover:fill-red-500"/></button>
                                                        </div>
                                                        <div className="col-span-2">
                                                            <label className="mx-3 my-2 text-[#E17272]">Post Code:</label>
                                                            <br/>
                                                            <input
                                                            className="w-[90%] h-[40px] px-3 py-3 my-2 mx-3 text-[#E17272] placeholder-[#E79494] focus:outline-none focus:border-[#DE4646]"
                                                            type="text"
                                                            name="postcode"
                                                            value={newaddress.postcode}
                                                            onChange={handleNewAddress}
                                                            />
                                                        </div>
                                                        <div className="col-span-2">
                                                            <label className="mx-3 my-2 text-[#E17272]">Address Type:</label>
                                                            <br/>
                                                            <select
                                                        className="w-[90%] h-[40px]  my-2 mx-3 text-[#E17272] placeholder-[#E79494] focus:outline-none focus:border-[#DE4646]"
                                                        type="text"
                                                        name="addresstype"
                                                        value={newaddress.addresstype}
                                                        onChange={handleNewAddress}
                                                        >
                                                            <option value="" disabled selected>Address Type</option>
                                                            <option value="Postal">Postal</option>   
                                                        </select>
                                                        </div>
                                                    </div>
                                                    </>
                                                ) : (
                                                    <>
                                                    <div className="ml-7">
                                                    <button type="reset" className=" h-7 flex bg-transparent text-[#E17272] border-0 hover:bg-transparent hover:text-red-500 fill-[#E17272] hover:fill-red-500" onClick={handleAddClick}>Add Address : <MdAddBox className="h-7 w-7 ml-2"/></button>
                                                    </div>
                                                    </>
                                                )}
                                        </form>   
                                    </div>  

                                </div>

                            </div>
                            </Tabs.Item>
                        </Tabs>

                        
                    </div>


                        
                    </div>
                </div>

    )
};

export default Profile