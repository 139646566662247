import React, {useEffect, useState} from 'react'
import Cyber from '../assets/Cyber.png';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import {  toast } from 'react-toastify';

const backendIP = process.env.BACKEND_IP;

const LinkedAccount = ( ) => {

  const navigate = useNavigate();

  const [loginname, setName] = useState('');
  const [type, setType] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [password, setPassword] = useState('');
  const [confirmpassword, setConfirmPassword] = useState('');
  const [securityemail, setSecurityEmail] = useState('');
  const [validForm, setValidForm] = useState(true);
  const [userData, setUserdata] = useState(null);

  const handleFirstnameChange = (value) => {
    setFirstname(value);
  }

  const handleLastnameChange = (value) => {
    setLastname(value);
  }

  const handleNameChange = (value) => {
    setName(value);
  }

  const handlePasswordChange = (value) => {
    setPassword(value);
  }

  const handleTypeChange = (value) => {
    setType(value);
  }

  const handleConfirmPasswordChange = (value) => {  
    setConfirmPassword(value);
  }

  const validateEmail = (email) => {
    // Simple email validation using regular expression
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSave = (event) => {
    event.preventDefault();

    // Check if all fields are filled
    if (
      firstname.trim() === '' ||
      lastname.trim() === '' ||
      loginname.trim() === '' ||
      password.trim() === '' ||
      confirmpassword.trim() === '' ||
      type.trim() === ''
    ) {
      setValidForm(false);
      toast.warning('Please fill in all fields', { position: 'top-center' });
      return;
    }

    // Check if passwords match
    if (password !== confirmpassword) {
      setValidForm(false);
      toast.warning("Please enter matching passwords.", { position: 'top-center' });
      return;
    }

    // Check if Emails match
    if (loginname === securityemail) {
      setValidForm(false);
      toast.warning("Security Email cannot be the same as Primary Email.", { position: 'top-center' });
      return;
    }

    if (!validateEmail(loginname)) {
      setValidForm(false);
      toast.warning("Please enter a valid Primary email address.", { position: 'top-center' });
      return;
    }

    const data = {
      
      user_type: type,
      name_first: firstname,
      name_last: lastname,
      login_name: loginname,
      password: password,
      security_email: securityemail,

    };
    
    const url = `${backendIP}/api/Auth/RegisterChild`; // Make sure to include the protocol (http:// or https://)

    // Get token from local storage
    const token = localStorage.getItem('token');

    if (token) {
      // Set Authorization header with the token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
    
    
    axios.post(url, data, config)
      .then((response) => {
        
          navigate('/profile');
          toast.success('User created successfully', { position: 'top-center' }); 
          
        
      })
      .catch((error) => {
        console.log('Error:', error); // Log the entire error object
        if (error.response) { // Check if error response exists
          toast.error(error.response.data.message, { position: 'top-center' });
        } else {
          toast.error('A Network error occurred. Please try again later.', { position: 'top-center' });
        }
        console.error('Error creating user:', error);
      });
    }
  };

  const fetchDataFromBackend = () => {
    // Get token from local storage
    const token = localStorage.getItem('token');

    // Check if token exists
    if (token) {
      // Set Authorization header with the token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };

      // Make a GET request to fetch data from backend
      axios.get(`${backendIP}/api/Auth/userinfo`, config)
        .then((response) => {
          setUserdata(response.data);
          //console.log(response.data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    } else {
      console.error('Token not found in local storage');
    }
  };

  useEffect(() => {
    fetchDataFromBackend();
  }, []);




    return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-white">
      {/* The main parent div */}
      
      <div className="bg-white p-10 rounded text-center">
      <Link to='/home'> <img src={Cyber} alt="Logo" className="w-[200px] h-[200px] mx-16 " /> </Link>
        <h1 className="text-3xl font-semibold m-6 ">Linked Accounts</h1>
        <h3 className="bg-gray-100 w-[60%] ml-16 rounded-2xl">{userData ? userData.email : 'Parent Account'}</h3>
        <div className="bg-white p-10 rounded shadow-md">
        <form onSubmit={handleSave}>
        <div className="mb-4 relative">
            <label htmlFor="name" className="text-gray-600 placeholder-gray-300 text-xs font-semibold absolute top-[-5px] left-3 bg-white p-1">
              First Name
            </label>
            <input
              type="text"
              placeholder="John"
              className="w-full px-3 py-3 my-2 text-center border border-gray-900 rounded focus:outline-none focus:border-[#DE4646]"
              onChange={(e) => handleFirstnameChange(e.target.value)}
            />
          </div>
          <div className="mb-4 relative">
            <label htmlFor="name" className="text-gray-600 placeholder-gray-300 text-xs font-semibold absolute top-[-5px] left-3 bg-white p-1">
              Last Name
            </label>
            <input
              type="text"
              placeholder="Doe"
              className="w-full px-3 py-3 my-2 text-center border border-gray-900 rounded focus:outline-none focus:border-[#DE4646]"
              onChange={(e) => handleLastnameChange(e.target.value)}
            />
          </div>
          <div className="mb-4 relative">
            <label htmlFor="name" className="text-gray-600 placeholder-gray-300 text-xs font-semibold absolute top-[-5px] left-3 bg-white p-1">
              Email
            </label>
            <input
              type="email"
              placeholder='Example@gmail.com'
              className="w-full px-3 py-3 my-2 text-center border border-gray-900 rounded focus:outline-none focus:border-[#DE4646]"
              onChange={(e) => handleNameChange(e.target.value)}
            />
          </div>
          <div className="mb-4 relative">
            <label htmlFor="name" className="text-gray-600 placeholder-gray-300 text-xs font-semibold absolute top-[-5px] left-3 bg-white p-1">
              Security Email
            </label>
            <input
              disabled
              type="email"
              value={userData ? userData.email : 'Parent Account'}
              className="w-full px-3 py-3 my-2 text-center border border-gray-900 rounded focus:outline-none focus:border-[#DE4646]"
              
            />
          </div>
          <div className="mb-4 relative">
            <label htmlFor="name" className="text-gray-600 placeholder-gray-300 text-xs font-semibold absolute top-[-5px] left-3 bg-white p-1">
              Password
            </label>
            <input
              type="password"
              placeholder = "Password"
              className="w-full px-3 py-3 my-2 text-center border border-gray-900 rounded focus:outline-none focus:border-[#DE4646]"
              onChange={(e) => handlePasswordChange(e.target.value)}
            />
          </div>
          <div className="mb-4 relative">
            <label htmlFor="name" className="text-gray-600 placeholder-gray-300 text-xs font-semibold absolute top-[-5px] left-3 bg-white p-1">
              Confirm Password
            </label>
            <input
              type="password"
              placeholder = "Password"
              className="w-full px-3 py-3 my-2 text-center border border-gray-900 rounded focus:outline-none focus:border-[#DE4646]"
              onChange={(e) => handleConfirmPasswordChange(e.target.value)}
            />
          </div>
          <div className="mb-4 relative">
          <label htmlFor="password" className="text-gray-600 placeholder-gray-300 text-xs font-semibold absolute top-[-5px] left-3 bg-white p-1">
            Account Type
          </label>
          <select
            className="w-full px-3 py-3 my-2 text-center border border-gray-900 rounded focus:outline-none focus:border-[#DE4646]"
            onChange={(e) => handleTypeChange(e.target.value)}
          >
            <option value="" disabled selected>Account Type</option>
            <option value="Personal">Personal</option>
            <option value="Org">Organisational</option>
          </select>
        </div>
          <button
            type="submit"
            className="bg-[#DE4646] ml-[0px] text-white w-full px-3 py-2 mt-7 rounded hover:bg-red-400"
          >
            Submit
          </button>
        </form>
        <div className='mt-6'>
            <p className="bg-gray-100 p-3 text-xs w-[250px] rounded-lg">
                <span className="text-red-600">Disclaimer:</span> This linked account will be a child account of the parent account email at the type, and will be linked to the same organisation if the parent account is a organisation top. if the child account is underage when the account owner is of age their account with be unlinked if they choose to do so via a email. 
            </p>
        </div>
      </div>
      </div>
    </div>
  );
}

export default LinkedAccount;