import React, {useState} from 'react'
import UploadSelect from './UploadSelect';
import axios from 'axios';
import { IoIosAddCircleOutline } from "react-icons/io";
import { FaRegSave } from "react-icons/fa";
import { GrDocumentUpdate } from "react-icons/gr";
import { IoIosRemoveCircleOutline } from "react-icons/io";
import { MdOutlineEdit } from "react-icons/md";
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AssetsUpload = () => {

    const [showForm, setShowForm] = useState(false);
    const [formData, setFormData] = useState({});
    const [dataArray, setDataArray] = useState([]);
    const [editIndex, setEditIndex] = useState(null);
    const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(null);

    const handleChange = (e) => {
        const { name, value, type } = e.target;
        const newValue = type === 'file' ? e.target.files[0] : value;
        setFormData({ ...formData, [name]: newValue });
      };

    const handleAdd = () => {

        // Checking if required fields are filled out
        if (!formData.docType || !formData.documentExt || !formData.docBin) {
            toast.warning("Please fill out all required fields only Expire Date is optional", { position: 'top-center' });
            return;
        }

        if (Object.keys(formData).length === 0) return;
        if (editIndex !== null) {
          // If editing, replace the existing data in the array
          const newDataArray = [...dataArray];
          newDataArray[editIndex] = formData;
          setDataArray(newDataArray);
          setEditIndex(null);
        } else {
          // If not editing, add new data to the array
          setDataArray([...dataArray, formData]);
        }
        setFormData({});
        setShowForm(false);
        console.log(dataArray);
    };
    
    const handleEdit = (index) => {
        setFormData(dataArray[index]);
        setShowForm(true);
        setEditIndex(index);
    };

    const handleCancel = () => {
        setFormData({});
        setShowForm(false);
        setEditIndex(null);
    };
    
    const handleDelete = (index) => {
        const newDataArray = [...dataArray];
        newDataArray.splice(index, 1);
        setDataArray(newDataArray);
    };


    const handleSubmit = (event) => {
        event.preventDefault();

        // Check if there is data to upload
        if (dataArray.length === 0) {
            toast.warning("No data to upload.", { position: 'top-center' });
            return;
        }
        
        // Get token from local storage
        const token = localStorage.getItem('token');
        
        const dataToSend = dataArray.map((item) => {
            // Convert the File field to a binary string
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => {
                    const arrayBuffer = reader.result;
                    const binaryString = new Uint8Array(arrayBuffer).reduce((data, byte) => {
                        return data + String.fromCharCode(byte);
                    }, '');
                    const docBin = btoa(binaryString); // Convert binary string to base64
                    resolve({
                        doc_type: item.docType,
                        doc_bin: docBin,
                        doc_ext: item.documentExt,
                        date_expiry: item.date_expiry
                    });
                };
                reader.onerror = () => {
                    reject(new Error('Unable to read file'));
                };
                reader.readAsArrayBuffer(item.docBin);
            });
        });
        
        Promise.all(dataToSend)
            .then((data) => {
                const payload = { documents: data };
                if (token) {
                    // Set Authorization header with the token
                    const config = {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    };

                    console.log(payload);
    
                    // Make a POST request to send data to the backend
                    axios.post('http://backend:5000/api/Doc_upload/UploadDocument', payload, config)
                        .then(() => {
                            console.log(payload);
                            console.log("Assets uploaded successfully!");
                            // Clear form data array after successful submission
                            setDataArray([]);
                            toast.success("Assets uploaded successfully!", { position: 'top-center' });
                            
                        })
                        .catch((error) => {
                            console.error('Error fetching data:', error);
                        });
                } else {
                    console.error('Token not found in local storage');
                }
            })
            .catch((error) => {
                console.error('Error reading file:', error);
            });
    };

    const handleDocumentPreview = (index) => {
        setSelectedDocumentIndex(index);
    };

    const renderDocumentPreview = () => {
        if (selectedDocumentIndex !== null && dataArray[selectedDocumentIndex]) {
            const selectedDocument = dataArray[selectedDocumentIndex];
            return (
                <div>
                    {selectedDocument.docBin ? (
                        <img src={URL.createObjectURL(selectedDocument.docBin)} alt="No Preview" style={{ maxWidth: '250px', maxHeight: '325px', width: '100%', height: '100%', objectFit: 'cover'}}/>
                    ) : (
                        <p>No preview available</p>
                    )}
                </div>
            );
        }
        return null;
    };
    

    return (
            <div class=" w-full h-screen pattern-wavy pattern-gray-200 pattern-bg-white pattern-size-6 pattern-opacity-100">
                <div className="container h-screen w-full flex justify-center items-center">

                    

                    <div className="rounded-3xl ml-20 content-center h-[700px] w-[600px]">


                        <div className="rounded-3xl content-center shadow-lg bg-white h-[800px] w-[1400px]">

                            <div className=" mx-10 border-b-2 border-red-200 ">
                                <h1 className="pt-10 text-4xl mb-3 font-semibold text-red-400">Data Upload</h1>
                            </div>

                            <div className="bg-white rounded-3xl h-[70%] mx-10 pt-5 grid grid-cols-4 gap-4 overflow-auto">

                                <div className="col-span-1">
                                    <div className="ml-5 mt-5 bg-red-100 w-[250px] h-[325px]">{renderDocumentPreview()}</div>
                                    <h1 className="ml-[35%] mt-2">PREVIEW</h1>
                                </div>

                                <div className="col-span-3 mt-5">
                                    <div className="grid grid-cols-8 border-b border-red-200">
                                        <div className="col-span-2">DOCUMENTS</div>
                                        <div className="col-span-6">TYPE</div>
                                    </div>
                                    <div>
                                
                                    {dataArray.length === 0 ? (
                                        <p>No documents added.</p>
                                    ) : (
                                        <div>
                                        {dataArray.map((data, index) => (
                                            <div key={index} className="grid grid-cols-5 border-b border-red-200 p-1" onClick={() => handleDocumentPreview(index)}>
                                            <div className="col-span-1">{index}</div>
                                            <div className="col-span-3">{data.documentExt}</div>
                                            <div className="col-span-1">
                                                <button onClick={() => handleDelete(index)} className="w-6 h-6 float-right border-0 hover:bg-transparent hover:text-red-700" >
                                                <IoIosRemoveCircleOutline className="w-5 h-5"/>
                                                </button>
                                                <button onClick={() => handleEdit(index)} className="w-6 h-6 float-right border-0 hover:bg-transparent hover:text-red-700">
                                                <MdOutlineEdit className="w-5 h-5"/>
                                                </button>
                                            </div>
                                            </div>
                                        ))}
                                        </div>
                                    )}
                                    </div>
                                    {!showForm && (
                                    <button onClick={() => setShowForm(true)} className="border-0 hover:bg-transparent hover:text-red-700 inline-flex">Add <IoIosAddCircleOutline className="mt-[5px] ml-1"/></button>
                                    )}
                                    {showForm && (
                                        <div className="inline-flex">
                                        <input className="bg-gray-50 p-1 border border-gray-300 mx-1" type="text" name="docType" placeholder="Document Type" onChange={handleChange} value={formData.docType || ''} />
                                        <input className="bg-gray-50 p-1 border border-gray-300 mx-1" type="text" name="documentExt" placeholder="Document Name" onChange={handleChange} value={formData.documentExt || ''} />
                                        <input className="bg-gray-50 p-1 border border-gray-300 mx-1" type="file" name="docBin" onChange={handleChange} />
                                        <input className="bg-gray-50 p-1 border border-gray-300 mx-1" type="date" name="date_expiry" placeholder="Expiry Date" onChange={handleChange} value={formData.date_expiry || ''} />
                                        <button className="ml-3 border-0 hover:bg-transparent hover:text-red-700" onClick={handleAdd}>{editIndex !== null ? <GrDocumentUpdate className="w-5 h-5 mt-2"/> : <FaRegSave className="w-5 h-5"/>}</button>
                                        <button className="ml-3 border-0 hover:bg-transparent hover:text-red-700" onClick={handleCancel}><IoIosRemoveCircleOutline className="w-5 h-5"/></button>
                                        </div>
                                    )}
                                    
                                </div>

                                                                    
                            </div>
                            <div>
                            <button onClick={handleSubmit} className='float-right mr-10 px-4 py-1 rounded-xl font-semibold bg-red-400 text-white hover:bg-red-300'>Upload</button>
                            </div>
                            
                        </div>
                        
                        
                    </div>
                </div>
            </div>
    )
};

export default AssetsUpload